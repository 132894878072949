.bg_components_box {
  /* border: 0px snow solid; */
  height: 100vh;
  position: relative;
}
.dynamic_data_box {
  position: absolute;
  left: 25px;
}

/* mines */
#tsparticles {
  /* position: fixed !important; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@-webkit-keyframes gradientfade {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 20%;
  }
}
@-moz-keyframes gradientfade {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 20%;
  }
}
@keyframes gradientfade {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 20%;
  }
}

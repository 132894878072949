body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
}

.note {
  font-family: monospace;
  color: black;
  background-color: snow;
  position: absolute;
  bottom: 0;
  right: 20px;
}

@-webkit-keyframes gradientfade {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 20%;
  }
}
@-moz-keyframes gradientfade {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 20%;
  }
}
@keyframes gradientfade {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 20%;
  }
}
